/**********************************NAVBAR*************************************************/
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

html{
  scroll-behavior: smooth;
}

:root{
  --primary-cream : #F2E0BD;
  --primary-dark: rgba(255,255,255, 0);
  --primary-cream2: #EADE9D;
  --primary-red: #D92B4B;
}


body{
  font-family: "Ubuntu", sans-serif;
}

nav {
  z-index: 2;
}

.logo{
  width: 6.25rem;
}


/*****MENU LINKS********/

nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li{
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 1rem;
  margin-left: 1rem;
  border-bottom: 0.13rem solid var(--primary-dark);
}

.navbar-nav > li > a.active, .navbar-nav > li > a.active:focus {
  border-bottom: 0.13rem solid var(--primary-cream);
}

.navbar-nav > li > a:hover {
  border-bottom: 0.13rem solid var(--primary-cream);
}

#navs{
  padding-left: 10.25rem;
  padding-right: 10.25rem;
}

.navbar-toggler{
  color: var(--primary-cream);
}


/*****HEADER********/

.header-wraper{
  position: relative;
  background: url(background1.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.main-info{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  z-index: 1;
}

.main-info h1 {
  color: #F2E0BD;
}

/*****TYPED TEXT********/
.typed-text{
  font-family: "Ubuntu", sans-serif;
  font-size: 2rem;
  color: var(--primary-cream2);
}

/*****PARTICLES********/

.tsparticles-canvas-el{
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px){
  .main-info h1 {
    font-size: 1.5rem !important;
  }

  .typed-text{
    font-size: 1.5rem !important;
  }
}

/*****ABOUT********/

.about-title{
  display: flex;
  position: relative;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  z-index: 1;
}

@media only screen and (min-width: 1025px){
.about-div {
  margin-left: 20rem;
  margin-right: 20rem;
}
}

.about-info{
  padding-top: 4rem;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.5rem;
}


/*****SKILLS********/

.skills-title{
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  z-index: 1;
}

@media only screen and (min-width: 1025px){
.skills-div{
  margin-left: 20rem;
  margin-right: 20rem;
}
}
.skills-info{
  padding-top: 1rem;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.5rem;
}


.buttons {
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  background-color: var(--primary-cream);
  border: none;
  color: black;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.1rem;
  margin: 2rem 1rem;
  border-radius: 2rem;
  transition-duration: 0.3s;
}

.buttons:hover{
  background-color: var(--primary-red);
  color: white;
}

/*****PROJECTS********/

.projects-title{
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  z-index: 1;
}

@media only screen and (min-width: 1025px){
.project-div{ 
  margin-left: 20rem;
  margin-right: 20rem;
} 
}

.project-info{
  padding-top: 1rem;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.5rem;
}

/*****HOBBIES********/

.hobbies-info{
  padding-top: 1rem;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.5rem;
}

.hobbies-subheaders{
  padding-top: 2rem;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
}
